<template>
  <HeadlineBlock
    :id="item?.fields.id ?? `section-${item.sys.id}`"
    :headline="headline"
    :cols="mappedCols"
    :cols-centered="item.fields.headingColumnPosition === 'centered'"
    :content-centered="item.fields.headingPosition === 'centered'"
    v-bind="
      ContentfulLivePreview.getProps({
        entryId: item.sys.id,
        fieldId: 'headlineText'
      })
    "
  />
</template>

<script setup lang="ts">
import { HeadlineBlock } from '@hypercodestudio/basler-components';
import { mapColsWidth } from '~/utils/mapColsWidth';
import type { IHeadline } from '~/lib/ContentfulService';
import { mapHeadline } from '~/utils/mapHeadline';
import { ContentfulLivePreview } from '@contentful/live-preview';

interface Props {
  item: IHeadline;
}

const props = defineProps<Props>();
const headline = computed(() => mapHeadline(props.item));
const mappedCols = computed(() => mapColsWidth(props.item.fields.headingWidth));
</script>
